@import "../../Themes/colors.scss";

@mixin InformationsPages-BaseButton {
  height: 120px;
  max-height: 120px;
  margin: 20px 16px;
  background-color: $informationsPagesElementBackgroundColor;
  border: 1px $black solid;
  box-shadow: 4px 4px $princeton_orange;
  &:not(:last-child) {
    margin-bottom: 28px;
  }

  color: $informationsPagesElementTextColor;
  text-transform: uppercase;
  font-family: "Fira Sans", sans-serif;
  font-size: 24px;
  font-weight: 600;
}

@mixin InformationsPages-Title {
  font-size: 32px;
  font-family: "Fira Sans", sans-serif;
  color: $informationsPagesElementTextColor;
}
