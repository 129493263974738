@import "../../InformationsPagesBase.scss";

.CGUPage-root {
  margin: 22px;
  color: $informationsPagesElementTextColor;

  .CGUPage-title {
    @include InformationsPages-Title;
  }

  > h2 {
    margin-top: 35px;
  }
}
