@import "../../Themes/colors.scss";
@import "./InformationsPagesBase.scss";

.InformationsPage-root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  background-image: url("../../assets/images/img_homepage_background.png");
  background-size: cover;

  .InformationsPage-mainButtons {
    display: flex;
    flex-direction: column;
    margin-top: 50px;

    .InformationsPage-button {
      @include InformationsPages-BaseButton;
    }

    .InformationsPage-button:active {
      background-color: $homePageListInfosColor;
      border: 1px $homePageListHoverColor solid;
      color: $homePageListHoverColor;
    }
  }

  .InformationsPage-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    height: 119px;
    p {
      font-family: 'Fira Sans', sans-serif;
      font-size: 12px;
      font-weight: 400;
    }
    img {
      width: 169px;
    }
  }
}
