@import "../InformationsPagesBase.scss";

.AboutPage-root {
  margin: 22px;
  color: $informationsPagesElementTextColor;

  .AboutPage-title {
    @include InformationsPages-Title;
  }

  .AboutPage-text {
    font-family: "Fira Sans", sans-serif;
  }
}
