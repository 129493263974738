.ArtWorkMapPage-root {
  .map-container {
    height: 100%;
    width: 100%;

    .Map-element {
      height: 100%;
    }

    .Map-marker {
      height: 50px;
      width: 50px;
      background-color: blue;
    }
  }
}
