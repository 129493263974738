@import "../../../Themes/colors.scss";

.ArtWorkDetailsPage-root {
  .ArtWorkDetailsPage-picture {
    position: relative;
    img {
      width: 100vw;
      height: 214px;
      max-height: 214px;
      object-fit: cover;
      @media (orientation: landscape) {
        max-height: 500px;
      }
    }
    .ArtWorkDetailsPage-fallbackImage {
      position: relative;
      z-index: 0;
    }
    .ArtWorkDetailsPage-urlImage {
      position: absolute;
      left: 0;
      z-index: 1;
      transition-delay: 0, 2s;
      transition: opacity 0.2s ease;
      background-color: white;
    }
  }

  .ArtWorkDetailsPage-infos {
    padding-left: 22px;
    padding-right: 22px;
    color: $artWorkDetailsPageTextColor;
    font-family: "Fira Sans", sans-serif;

    .ArtWorkDetailsPage-header {
      display: flex;
      justify-content: space-between;
      .ArtWorkDetailsPage-header-infos {
        font-weight: 300;
        .ArtWorkDetailsPage-title {
          font-size: 32px;
          font-weight: 900;
        }

        .ArtWorkDetailsPage-artist {
          font-size: 24px;
          margin-bottom: 5px;
          line-height: 1.2;
        }

        .ArtWorkDetailsPage-subtitle {
          font-size: 18px;
          margin-bottom: 20px;
        }
      }
      .ArtWorkDetailsPage-actions {
        button {
          margin-right: -12px;
        }
      }
    }

    .ArtWorkDetailsPage-shortDescription {
      font-size: 16px;
      font-family: "Fira Sans", sans-serif;
      margin: 20px 0;
    }

    .ArtWorkDetailsPage-border {
      height: 4px;
      background-color: $artWorkDetailsPageBorderColor;
      width: 45vw;
      margin: 0 auto 20px;
    }

    .ArtWorkDetailsPage-description {
      font-size: 16px;
      font-family: "Fira Sans", sans-serif;

      img {
        display: block;
        width: 90%;
        max-height: 500px;
        margin: 0 auto 10px;
        object-fit: contain;
      }

      video {
        display: block;
        width: 90%;
        max-height: 500px;
        margin: 0 auto 10px;
        object-fit: contain;
      }
    }

    .ArtWorkDetailsPage-copyrights {
      font-size: 10px;
      font-family: "Fira Sans", sans-serif;
      margin-top: 20px;
      font-style: italic;
      margin-bottom: 10px;
    }

    .ArtWorkDetailsPage-country {
      font-weight: 500;
      font-size: 18px;
    }
  }
}
