@import "../../../Themes/colors.scss";

.Search-root {
  width: 90vw;
  height: 35px;

  form {
    height: 35px;
  }

  .Search-formControl {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid $searchBorderColor;
    height: 35px;

    &:focus-within {
      border-bottom: 2px solid $searchBorderColor;
    }

    .search-input {
      background-color: $searchInputBackgroundColor;
      color: $searchInputTextColor;
      height: 33px;
      font-family: "Fira Sans", sans-serif;
      font-weight: 300;
      font-size: 18px;

      ::placeholder {
        color: $searchInputTextColor;
        opacity: 0.7;
      }

      .Search-startAdornment {
        margin-left: -6px;
        margin-right: 8px;
        align-self: center;
        height: 16px;
      }

      .Search-endAdornment {
        display: flex;

        .Search-EndAdornmentClear {
          align-self: center;
          height: 30px;
          width: 30px;
          padding: 0px;

          .MuiIconButton-label {
            width: fit-content;
            height: fit-content;
            img {
              height: 16px;
              width: 16px;
            }
          }
        }
      }
    }

    .Search-button {
      padding: 0;

      img {
        height: 18px;
        width: 18px;
      }
    }
  }
}
