@import "../../Themes/theme.scss";

.NewsDialog-root {
  overflow-y: hidden !important;
  width: calc(100% - 30px) !important;
  height: calc(100% - 30px) !important;
}
.NewsDialog-dialogContent-root {
  display: grid;
  grid-template-rows: auto auto auto;
  overflow: hidden;
  font-family: "Fira Sans", sans-serif;
  margin: 0 15px;

  h3 {
    font-size: 18px;
    grid-row: 1;
    color: $artWorkListPageTitleColor;
    font-family: "Fira Sans", sans-serif;
  }

  .NewsDialog-dialogContent-picture {
    position: relative;
    img {
      width: 100%;
      max-height: 500px;
      grid-row: 2;
      object-fit: contain;
    }

    .NewsDialog-dialogContent-fallbackImage {
      z-index: 0;
    }

    .NewsDialog-dialogContent-urlImage {
      position: absolute;
      z-index: 1;
      top: 0;
      bottom: 0;
      margin: auto 0;
      transition-delay: 0, 2s;
      transition: opacity 0.2s ease;
      background-color: white;
    }
  }

  .description {
    grid-row: 3;
    margin-top: 16px;
  }

  .NewsDialog-dialogContent-helpText {
    margin-bottom: 24px;
  }

  .NewsDialog-dialogContent-downloadLink {
    margin-bottom: 24px;

    & > img {
      height: 60px;
    }
  }
}
