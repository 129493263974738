.PrestationMap-loading {
    height: 100%;
}

.PrestationMap-container {
    height: 490px;
}

.PrestationMap-element {
    height: 100%;
}
