@import "../../InformationsPagesBase.scss";

.LicencesPage-root {
  margin: 22px;

  .LicencesPage-title {
    @include InformationsPages-Title;
  }

  .LicencesPage-licenceRoot {
    margin: 15px;
    .LicencesPage-licenceName {
      font-size: 16px;
      font-weight: bold;
    }

    .LicencesPage-licenceAuthor {
      text-decoration: underline;
    }
  }
}
