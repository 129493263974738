@import "../../../Themes/colors.scss";

.custom-toast-container {
  display: flex;
  flex-direction: row;
  width: 510px;
  min-height: 100px;
  margin: -6px;

  &.Success {
    color: $customToastTextSuccessColor;
    background-color: $customToastBackgroundSuccessColor;
  }

  &.Error {
    color: $customToastTextErrorColor;
    background-color: $customToastBackgroundErrorColor;
  }

  &.Info {
    color: $customToastTextInfoColor;
    background-color: $customToastBackgroundInfoColor;
  }

  .body {
    width: inherit;
    display: flex;
    flex-direction: row;
    padding-bottom: 24px;

    .title {
      margin-top: 21px;
      margin-left: 24px;
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
    }

    .content {
      margin-left: 24px;
      margin-top: 15px;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      word-wrap: break-word;
    }

    .customToast-action {
      margin-left: 18px;
      width: 100%;
      margin-top: 12px;
      margin-bottom: 12px;
    }

    .close-icon {
      img {
        width: 30px;
        height: 30px;
      }
      margin-left: auto;
    }
  }

  .toast-icon {
    margin: auto 29px auto 30px;
    height: 70px;
    width: 70px;
  }
}
