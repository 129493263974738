$white: #ffffff;
$black: #000000;
$saphire: #2b2fa4;
$tangerine_yellow: #ffce00;
$orange_red: #ff3600;
$princeton_orange: #ee7c24;

//* Toast
$customToastTextSuccessColor: $white; //! REPLACE
$customToastBackgroundSuccessColor: #00c851; //! REPLACE

$customToastTextErrorColor: $white; //! REPLACE
$customToastBackgroundErrorColor: $orange_red; //! REPLACE

$customToastTextInfoColor: $white; //! REPLACE
$customToastBackgroundInfoColor: $orange_red; //! REPLACE

$customToastContentTextColor: $white; //! REPLACE
$customToastBodyBackgroundColor: $orange_red; //! REPLACE

//* Dialogs
$dialogHeaderBackgroundColor: black; //! REPLACE
$dialogHeaderTextColor: black; //! REPLACE
$dialogTextColor: black; //! REPLACE

//* Buttons
$buttonStandardTextColor: $white;
$buttonStandardBackgroundColor: $saphire;
$buttonStandardBackgroundHoverColor: rgba($saphire, 0.7);

$buttonDangerBackgroundColor: black; //! REPLACE
$buttonDangerTextColor: black; //! REPLACE
$buttonDangerBorderColor: black; //! REPLACE
$buttonDangerBackgroundHoverColor: black; //! REPLACE

$buttonSuccessBackgroundColor: black; //! REPLACE
$buttonSuccessTextColor: black; //! REPLACE
$buttonSuccessBackgroundHoverColor: black; //! REPLACE

$buttonOutlinedBackgroundColor: black; //! REPLACE
$buttonOutlinedTextColor: black; //! REPLACE
$buttonOutlinedBackgroundHoverColor: black; //! REPLACE
$buttonOutlinedBorderColor: black; //! REPLACE

$buttonLinkTextColor: black; //! REPLACE

//* Loader
$loaderBackgroundColor: black; //! REPLACE

//* Select
$selectBorderColor: black; //! REPLACE
$selectBackgroundColor: black; //! REPLACE
$selectInputTextColor: black; //! REPLACE
$selectIconBackgroundColor: black; //! REPLACE
$selectIconDisabledBackgroundColor: black; //! REPLACE
$selectEmptyBackgroundColor: black; //! REPLACE
$selectEmptyInputTextColor: black; //! REPLACE
$selectErrorBorderColor: black; //! REPLACE

//* Switch
$switchLabelColor: black; //! REPLACE
$switchUncheckedColor: black; //! REPLACE
$switchCheckedColor: black; //! REPLACE
$switchThumbColor: black; //! REPLACE

//* Input
$inputTextLabelColor: black; //! REPLACE
$inputTextTextColor: black; //! REPLACE
$inputTextErrorColor: black; //! REPLACE

$inputTextStandardBackgroundColor: black; //! REPLACE
$inputTextStandardBorderColor: black; //! REPLACE
$inputTextStandardBorderColorFocus: black; //! REPLACE

$inputTextOutlinedBackgroundColor: black; //! REPLACE
$inputTextOutlinedBorderColor: black; //! REPLACE
$inputTextOutlinedBorderColorFocus: black; //! REPLACE

$inputTextFilledBackgroundColor: black; //! REPLACE
$inputTextFilledLabelColor: black; //! REPLACE

//* Media input
$mediaInputItemBackgroundColor: black; //! REPLACE
$mediaInputItemBorderColor: black; //! REPLACE
$mediaInputErrorBorderColor: black; //! REPLACE
$mediaInputdocumentBackgroundColor: black; //! REPLACE

//* No results
$noResultTextColor: black; //! REPLACE

//* Paginated table
$paginatedTableBorderColor: black; //! REPLACE
$paginatedTableHeaderTextColor: black; //! REPLACE
$paginatedTableHeaderBackgroundColor: black; //! REPLACE
$paginatedTableBackgroundColor: black; //! REPLACE
$paginatedTableRowItemTextColor: black; //! REPLACE
$paginatedTableRowBackgroundColor: black; //! REPLACE
$paginationItemTextColor: black; //! REPLACE
$paginationItemSelectedBorderBackgroundColor: black; //! REPLACE
$paginationItemSelectedBackgroundColor: black; //! REPLACE
$paginationItemSelectedTextColor: black; //! REPLACE

//* Popover menu
$popoverLabelTextColor: black; //! REPLACE

//* Search
$searchInputBackgroundColor: $white;
$searchInputBackgroundColorFocus: $white;
$searchBorderColor: $orange_red;
$searchInputTextColor: $black;

//* Tabs
$tabTextColor: black; //! REPLACE
$tabSelectedTextColor: black; //! REPLACE
$tabSelectedUnderlineColor: black; //! REPLACE
$tabUnderlineColor: black; //! REPLACE
$tabBackgroundColor: black; //! REPLACE

//* Card
$cardHeaderBackgroundColor: black; //! REPLACE
$cardHeaderTextColor: black; //! REPLACE
$cardBodyBackgroundColor: black; //! REPLACE

//* Radio
$radioGroupLabelTextColor: black; //! REPLACE
$radioGroupErrorColor: black; //! REPLACE
$radioLabelColor: black; //! REPLACE
$radioLabelCheckedColor: black; //! REPLACE
$radioEffectColor: black; //! REPLACE

//* Header
$headerBackgroundColor: $white;
$headerTextColor: $black;

//! PAGES

//* Home Page
$homePageListElementBackgroundColor: $white;
$homePageListHoverColor: $white;
$homePageListMerColor: $tangerine_yellow;
$homePageListCanalColor: $orange_red;
$homePageListElementInfosTextColor: $black;
$homePageListInfosColor: $princeton_orange;

//* Canal List Page
$canalListPageBorderColor: $orange_red;

//* Mer List Page
$merListPageBorderColor: $tangerine_yellow;

//* ArtWork List Page
$artWorkListPageTitleColor: $black;
$artWorkListPageTextColor: $black;
$artWorkListPageFilterBackgroundColor: $orange_red;
$artWorkListPageArtWorkBackgroundColor: $white;
$artWorkListPageArtWorkColorActive: $white;
$artWorkListPageArtWorkCanalBackgroundColorActive: $orange_red;
$artWorkListPageArtWorkMerBackgroundColorActive: $tangerine_yellow;
$artWorkListPage3dBackgroundColor: $white;
$artWorkListPage3dBorderColor: $orange_red;
$artWorkListPage3dTextColor: $orange_red;

//* ArtWork Filter Page
$artWorkFilterPageFilterHelpTextColor: $saphire;
$artWorkFilterPageFilterBorderColor: $saphire;
$artWorkFilterPageFilterTextColor: $saphire;
$artWorkFilterPageFilterActiveBackgroundColor: $saphire;
$artWorkFilterPageFilterActiveTextColor: $white;
$artWorkFilterPageFooterBackgroundColor: $saphire;
$artWorkFilterPageFooterTextColor: $white;

//* ArtWork Details Page
$artWorkDetailsPageTextColor: $black;
$artWorkDetailsPageBorderColor: $black;

//* Informations Pages
$informationsPagesBackgroundColor: $white;
$informationsPagesElementBackgroundColor: $white;
$informationsPagesElementTextColor: $black;
$informationsPagesAgendaTextColor: $white;
