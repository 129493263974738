@import "../../../Themes/colors.scss";
@import "../InformationsPagesBase.scss";

.LegalNoticePage-root {
  background-color: $informationsPagesBackgroundColor;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-image: url("../../../assets/images/img_homepage_background.png");
  background-size: cover;
  .LegalNoticePage-buttons {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    .LegalNoticePage-button {
      @include InformationsPages-BaseButton;
    }
    .LegalNoticePage-button:active {
      background-color: $homePageListInfosColor;
      border: 1px $homePageListHoverColor solid;
      color: $homePageListHoverColor;
    }
  }
}
