@import "../../../../Themes/theme.scss";

.CustomDialog-root {
  min-width: 90% !important;
  border-radius: 0px !important;

  .CustomDialog-header {
    display: flex;
    align-items: center;
    padding: 5px 10px;

    .CustomDialog-header-closeButton {
      margin-left: auto;

      img {
        height: 20px;
      }
    }
  }

  .CustomDialog-body {
    min-height: 100px;
    padding: 10px;
  }

  .CustomDialog-actions {
    display: flex;

    .Button-root {
      width: 45%;
    }
  }
}
