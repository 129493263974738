@import "../../Themes/colors.scss";

.ArtWorkListPage-root {
  font-family: "Fira Sans", sans-serif;

  .ArtWorkListPage-title {
    font-size: 32px;
    color: $artWorkListPageTitleColor;
    text-align: center;
    margin-top: 2%;
  }

  .ArtWorkListPage-header-image {
    width: 100vw;
    max-height: 214px;
    object-fit: cover;
    @media (orientation: landscape) {
      max-height: 500px;
    }
  }

  .ArtWorkListPage-header-title {
    margin-top: 10px;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
  }

  .ArtWorkListPage-header-description {
    padding: 15px;
    color: $artWorkListPageArtWorkBackgroundColor;
  }

  .ArtWorkListPage-actions {
    display: flex;
    margin-top: 20px;
    margin-bottom: 5%;
    justify-content: center;

    .ArtWorkListPage-filter {
      background-color: $artWorkListPageFilterBackgroundColor;
      color: $artWorkListPageTextColor;
      font-size: 14px;
      font-family: "Fira Sans", sans-serif;
      height: 35px;
      width: 30%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin-left: 20px;
    }
  }

  .ArtWorkList-list {
    height: fit-content;

    .ParcoursMer {
      border: 1px $merListPageBorderColor solid;
      box-shadow: 4px 4px $merListPageBorderColor;
    }

    .ParcoursMer:active {
      background-color: $artWorkListPageArtWorkMerBackgroundColorActive;
      border: 1px $artWorkListPageArtWorkColorActive solid;
    }

    .ParcoursCanal {
      border: 1px $canalListPageBorderColor solid;
      box-shadow: 4px 4px $canalListPageBorderColor;
    }

    .ParcoursCanal:active {
      background-color: $artWorkListPageArtWorkCanalBackgroundColorActive;
      border: 1px $artWorkListPageArtWorkColorActive solid;
    }

    .ArtWorkList-element:active {
      color: $artWorkListPageArtWorkColorActive;
    }

    .ArtWorkList-element {
      position: relative;
      max-height: 200px;
      margin: 20px 16px;
      display: flex;
      background-color: $artWorkListPageArtWorkBackgroundColor;
      color: $artWorkListPageTextColor;

      .ArtWorkList-elementImage {
        img {
          width: 154px;
          height: 198px;
          object-fit: cover;
        }

        .ArtWorkList-fallbackImage {
          position: relative;
          z-index: 0;
        }

        .ArtWorkList-urlImage {
          position: absolute;
          left: 0;
          z-index: 1;
          transition-delay: 0, 2s;
          transition: opacity 0.2s ease;
          background-color: white;
        }
      }

      .ArtWorkList-elementDetails {
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-left: 36px;
        margin-right: 10px;
        font-size: 18px;
        font-family: "Fira Sans", sans-serif;

        .ArtWorkList-elementAuthor {
          font-weight: 400;
        }

        .ArtWorkList-elementTitle {
          font-weight: 900;
        }
      }

      .ArtWorkList-element3d {
        background-color: $artWorkListPage3dBackgroundColor;
        height: 33px;
        width: 33px;
        margin-left: auto;
        margin-right: 14px;
        border-radius: 0px 0px 5px 5px;
        border: 1px solid $artWorkListPage3dBorderColor;
        margin-top: -1px;
        color: $artWorkListPage3dTextColor;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "Fira Sans", sans-serif;
      }
    }
  }
}
