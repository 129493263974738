@import "../../../Themes/colors.scss";

.Header-root {
  display: flex;
  height: 100%;
  flex-direction: column;

  .Header-header {
    min-height: 90px;
    background-color: $headerBackgroundColor;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      color: $headerTextColor;
      font-size: 32px;
      font-family: "Fira Sans", sans-serif;
    }

    .Header-back {
      margin-left: 16px;
      margin-right: auto;
      height: 60px;
      width: 60px;
    }

    .Header-close {
      margin-right: 16px;
      margin-left: auto;
      height: 60px;
      width: 60px;
    }

    .Header-logo {
      width: 65px;
      height: 61px;
    }

    .ParcoursArty {
      width: auto;
      height: 61px;
    }
  }

  .ParcoursArty-Header {
    min-height: 170px;
    .Header-logo {
      width: auto;
      height: 87px;
    }
  }

  .Header-border-root {
    position: sticky;
    align-items: center;
    padding: 5px 0 10px;
    background-color: white;
    .Header-border {
      height: 4px;
      background-color: $artWorkDetailsPageBorderColor;
      width: 45vw;
      margin: 0 auto;
    }
  }

  .Header-children {
    flex-grow: 1;
    overflow: auto;
  }
}
