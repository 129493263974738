@import "../../Themes/theme.scss";

.SectionListPage-Dialog-paper {
  max-width: 500px !important;
  min-width: MIN(90%, 100px) !important;
}

.SectionListPage-dialogContent-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-family: "Fira Sans", sans-serif;

  .SectionListPage-dialogContent-helpText {
    margin-bottom: 24px;
  }

  .SectionListPage-dialogContent-downloadLink {
    margin-bottom: 24px;

    & > img {
      height: 60px;
    }
  }
}

.SectionListPage-root {
  font-family: "Fira Sans", sans-serif;
  display: flex;
  flex-direction: column;
  height: 100vh;

  .SectionListPage-header {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 87px;
    margin-top: 42px;
    margin-bottom: 25px;

    .SectionListPage-logo {
      height: 77px;
    }
    @media only screen and (min-width: 325px) {
      .SectionListPage-logo {
        height: 87px;
      }
    }
  }

  .SectionListPage-title {
    font-size: 32px;
    color: $artWorkListPageTitleColor;
    font-family: "Fira Sans", sans-serif;
    margin-top: 2%;
    padding-left: 15px;
  }

  .SectionListPage-actions {
    display: flex;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 20px;
    margin-bottom: 5%;
  }

  .SectionListPage-list {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-image: url("../../assets/images/img_homepage_background.png");
    background-size: cover;

    .SectionListPage-element {
      display: flex;
      background-color: $homePageListElementBackgroundColor;
      &:not(:last-child) {
        margin-bottom: 28px;
      }
      height: 120pt;
      max-height: 120pt;
      margin: 20px 16px;
      font-size: 15pt;
      font-weight: 600;
      cursor: pointer;

      img {
        height: 36pt;
        width: 36pt;
        margin: auto 24px auto auto;
      }
      @media only screen and (min-width: 325px) {
        font-size: 18pt;
        img {
          height: 50pt;
          width: 50pt;
        }
      }
      @media only screen and (min-width: 425px) {
        font-size: 24pt;
        img {
          height: 64pt;
          width: 64pt;
        }
      }
    }

    .ParcoursCanal {
      border: 1px $homePageListCanalColor solid;
      box-shadow: 4px 4px $homePageListCanalColor;
      .SectionListPage-elementTitle {
        color: $homePageListCanalColor;
      }
    }

    .ParcoursCanal:active {
      background-color: $homePageListCanalColor;
      border: 1px $homePageListHoverColor solid;
      .SectionListPage-elementTitle {
        color: $homePageListHoverColor;
      }
      img {
        filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(169deg) brightness(102%) contrast(102%);
      }
    }

    .ParcoursMer {
      border: 1px $homePageListMerColor solid;
      box-shadow: 4px 4px $homePageListMerColor;
      .SectionListPage-elementTitle {
        color: $homePageListMerColor;
      }
      img {
        filter: brightness(0) saturate(100%) invert(70%) sepia(79%) saturate(538%) hue-rotate(359deg) brightness(102%) contrast(106%);
      }
    }

    .ParcoursMer:active {
      background-color: $homePageListMerColor;
      border: 1px $homePageListHoverColor solid;
      .SectionListPage-elementTitle {
        color: $homePageListHoverColor;
      }
      img {
        filter: none;
      }
    }

    .SectionListPage-elementTitle {
      margin: auto 0 auto 24px;
      max-width: 50%;
      line-height: 30px;
      .highlight {
        font-size: 0.5em;
        display: block;
        line-height: 18px;
      }
    }

    .SectionListPage-elementInfos {
      display: flex;
      background-color: $homePageListElementBackgroundColor;
      border: 1px $homePageListInfosColor solid;
      box-shadow: 4px 4px $homePageListInfosColor;
      height: 56pt;
      max-height: 56pt;
      margin: 20px 16px;
      font-size: 24px;
      font-family: "Fira Sans", sans-serif;
      font-weight: 600;
      align-items: center;
      text-transform: uppercase;
      justify-content: center;
      color: $black;
      cursor: pointer;

      .SectionListPage-elementInfosTitle {
        display: flex;
        margin-left: 16px;
      }
    }

    .SectionListPage-elementInfos:active {
      background-color: $homePageListInfosColor;
      border: 1px $homePageListHoverColor solid;
      .SectionListPage-elementInfosTitle {
        color: $homePageListHoverColor;
      }
    }
  }
}
